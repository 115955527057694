import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MeetTheTeam from "../components/MeetTheTeam"

const MeetTheTeamPage = () => (
  <Layout>
    <Seo title="Meet the team" />
    <MeetTheTeam />
  </Layout>
)

export default MeetTheTeamPage
